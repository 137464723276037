// src/redux/slice/tanProfilesSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { filterApi } from "../services/filterApi"
const baseState = {
  imgUUID: null,
  localImage: null,
  upload: null,
  modifiedUrl: null,
  loading: false
}
const filterSlice = createSlice({
  name: 'filterSlice',
  initialState: baseState,
  reducers: {
    setLocalImage: (state, action) => {
      state.localImage = action.payload;
    },
    setUUID: (state, action) => {
      if(!state.imgUUID){
        state.imgUUID = action.payload;
      }
    },
    clearFilterData: (state, action) => {
      return baseState
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(filterApi.endpoints.uploadImage.matchFulfilled, (state, { payload }) => {
      // console.log(payload);
      state.upload = payload?.payload; // Capture the payload in state
      state.loading = false
    })
    .addMatcher(filterApi.endpoints.uploadImage.matchRejected, (state, { error }) => {
      console.error('Upload failed:', error);
    })
    .addMatcher(filterApi.endpoints.modifyImage.matchFulfilled, (state, { payload }) => {
      console.log(payload);
      state.modifiedUrl = payload?.payload.reduce((max, obj) => {
        return (max === null || parseInt(obj.id) > parseInt(max.id)) ? obj : max;
    }, null).image; // Capture the payload in state
    state.loading = false
    })
    .addMatcher(filterApi.endpoints.modifyImage.matchRejected, (state, { error }) => {
      console.error('Upload failed:', error);
    });
  },
});

export const { setLocalImage, clearFilterData, setUUID, setLoading } = filterSlice.actions;
export default filterSlice.reducer;
