import React from "react";
import "./squareButton.css";

const SquareButton = (props) => {
    let className = "square-btn"
    if(props.className){
        className += " " + props.className
    }
  return (
    <button className={className} onClick={()=> props.onClick ? props.onClick() : null}>{props.children}</button>  
)

};

export default SquareButton;
