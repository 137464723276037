import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import CircleButton from '../circleButton/circleButton';
import { useTranslation } from 'react-i18next';

// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import "./greetingCarousel.css"
function GreetingCarousel() {
    const { t } = useTranslation("translation", {keyPrefix: "greetingCarousel"});
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const clickableItems = []
    const itemOptions = [
        {
            text: t("itemOptions.stances", "Spray tan stances"),
            url: "/"
        },
        {
            text: "Pre & post tan care",
            url: "/"
        },
        {
            text: "Spray tan guide",
            url: "/"
        },
        {
            text: "How to apply barrier cream",
            url: "/"
        },
        {
            text: "What's your tan number",
            url: "/"
        },
        {
            text: "Spray tan myths",
            url: "/"
        },
        {
            text: "Magnetan technology™",
            url: "/"
        },
        
    ]
    const colorOptions = [
        getComputedStyle(document.body).getPropertyValue('--unity-primary-purple'),
        getComputedStyle(document.body).getPropertyValue('--unity-primary-blue'),
        getComputedStyle(document.body).getPropertyValue('--unity-secondary-yellow'),
        getComputedStyle(document.body).getPropertyValue('--unity-primary-violet'),
        getComputedStyle(document.body).getPropertyValue('--unity-secondary-violet'),
        getComputedStyle(document.body).getPropertyValue('--unity-secondary-pink'),
    ]
    let threestack = []
    let colorChoice = 0
    const stackIt = () => {
        clickableItems.push(<Carousel.Item><div className='carousel-div'>{threestack}</div></Carousel.Item>)
        threestack = []
    }
    for(let i in itemOptions){
        threestack.push(<CircleButton style={{backgroundColor: colorOptions[colorChoice]}}>{itemOptions[i].text}</CircleButton>)
        if(threestack.length === 3){
            stackIt()
        }
        if(colorChoice < colorOptions.length - 1){
            colorChoice++
        }else{
            colorChoice = 0
        }
    }
    if(threestack.length > 0){
        stackIt()
    }

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} className='tan-carousel' data-bs-theme="dark">
            {clickableItems}
        </Carousel>
    );
}

export default GreetingCarousel;