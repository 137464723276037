// src/redux/slice/tanProfilesSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { tanHistoryApi } from '../services/historyApi';

const historySlice = createSlice({
  name: 'history',
  initialState: {
    data: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(tanHistoryApi.endpoints.getTanHistory.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        state.data = payload?.payload; 
    })
    .addMatcher(
      tanHistoryApi.endpoints.getTanHistory.matchRejected,
      (state, { error, meta }) => {
          state.error = error; // Store error in state
          state.data = {}; // Clear data on error
      });
  },
});

export default historySlice.reducer;
