import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setSidebarOpen } from '../redux/slice/displaySlice';
import { persistor } from '../redux/store/store';
// import { logout } from '../redux/slice/authSlice';
import "bootstrap-icons/font/bootstrap-icons.css";
import { setDisplay } from '../redux/slice/displaySlice';
// import Sidebar from './sidebar'; // Import the Sidebar component
import './Layout.css'; // Ensure your custom CSS is imported
import Offcanvas from 'react-bootstrap/Offcanvas';
import CircleButton from '../components/circleButton/circleButton';
const Layout = () => {
  const sidebar = useSelector((state) => state?.display?.sidebar)    
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const isWide = windowSize.width > windowSize.height - (windowSize.height / 3)
  const user = useSelector((state) => state?.auth?.user);
  const dispatch = useDispatch();
  const letsGo = useNavigate();
  const fullPath = window.location.pathname;
  const firstSegment = "/" + fullPath.split('/')[1];
  const display = useSelector((state) => state.display.display)    
  const isSidebarOpen=sidebar?.open


  const toggleSidebar = () => dispatch(setSidebarOpen(!sidebar?.open));
  const closeSidebar = () => dispatch(setSidebarOpen(false));

  const handleClick = (callback) => {
    closeSidebar();
    callback();
  };
  const yeetTheData = () => {
    persistor.purge().then(() => {
      console.log('Persisted data cleared');
      window.location.reload()
    }).catch((error) => {
      console.error('Failed to clear persisted data', error);
    });
  }

  if(display.width === display.height === 0){
    dispatch(setDisplay({ width: window.innerWidth, height: window.innerHeight, isWide: windowSize.width > windowSize.height - (windowSize.height / 3) }))
  }
  useEffect(() => {
    const handleResize = () => {
      let wide2 = window.width > window.height - (window.height / 3)
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      // dispatch(setDisplay({ width: window.innerWidth, height: window.innerHeight, isWide: window.width > window.height - (window.height / 3) }));
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    let wide2 = windowSize.width > windowSize.height - (windowSize.height / 3)
    console.log(wide2)
    console.log(isWide)
    dispatch(setDisplay({ width: windowSize.innerWidth, height: windowSize.innerHeight, isWide: windowSize.width > windowSize.height - (windowSize.height / 3) }));
  },[windowSize])

  const NavContent = () => {

    return (<Nav variant="underline" defaultActiveKey={firstSegment} className="flex-column">
      {user && <>
        <Nav.Link active={firstSegment === "/greeting"} onClick={() => handleClick(() => letsGo("/greeting"))}>Tanning Home</Nav.Link>
        <Nav.Link active={firstSegment === "/account"} onClick={() => handleClick(() => letsGo("/account"))}>Account</Nav.Link>
        <Nav.Link active={firstSegment === "/tanProfiles"} onClick={() => handleClick(() => letsGo("/tanProfiles"))}>Tan Profiles</Nav.Link>
        <Nav.Link active={firstSegment === "/history"} onClick={() => handleClick(() => letsGo("/history"))}>Tan history</Nav.Link>
      </>}

      <Nav.Link onClick={() => window.open("https://sunless.com/pages/salon-locator", '_blank').focus()}>Find a location</Nav.Link>
      {user ? (
        <Nav.Link active={firstSegment === "/login"} onClick={() => handleClick(() => yeetTheData())}>Logout</Nav.Link>
      ) : (
        <Nav.Link active={firstSegment === "/login"} onClick={() => handleClick(() => letsGo("/login"))}>Login</Nav.Link>
      )}
    </Nav>)
  }
  const MainContent = () => {
    return (
      <div className="outlet flex-grow-1 main-content" style={{padding: "0px"}}>
        <Outlet />
      </div>)
  }

  const IconFooter = () => {
    return (<div style={{
      display: 'flex',           // Set up as a flex container
      justifyContent: 'space-around', // Space icons equally
      alignItems: 'flex-end',    // Align items to the bottom
      fontSize: '70px',          // Large icon size
      textAlign: 'center',       // Center text inside container
      padding: '0',              // Remove padding
      margin: '0',               // Remove margin
      alignSelf: 'flex-end',
    }}>
      <i class="bi bi-instagram" onClick={() => { window.open("https://www.instagram.com/mystictan/", '_blank').focus() }}></i>
      <i class="bi bi-tiktok" onClick={() => { window.open("https://www.tiktok.com/@mystictan_official", '_blank').focus() }}></i>
    </div>)
  }

  // const CircleButton = (props) => {
  //   return (
  //     <div onClick={() => props.onClick()} className="circle-btn">
  //       <div className="circle-div">
  //         <div>LOG</div>
  //         <div>OUT</div>
  //       </div>

  //     </div>
  //   );
  // };

  return (
    <>
      {user ?
        <div className="d-flex flex-column min-vh-100 fullContainer">
          <Navbar bg="light" variant="light" expand="lg">
            <div className='navbarContainer'>
              <Navbar.Toggle style={{ border: "none", display: isWide ? "none" : ""}} aria-controls="basic-navbar-nav" onClick={toggleSidebar}><div className='menuToggle'><span/><span/><span/></div></Navbar.Toggle>
              <Navbar.Brand onClick={() => letsGo("/")}>Unity</Navbar.Brand>
              <CircleButton onClick={() => yeetTheData()}secondLine="OUT">LOG</CircleButton>
            </div>
          </Navbar>

          {!isWide &&
            <Offcanvas show={isSidebarOpen} onHide={closeSidebar}>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Unity</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <NavContent />
              </Offcanvas.Body>
              <Offcanvas.Body placement="end" style={{ display: 'grid', }}>
                <IconFooter />
              </Offcanvas.Body>
            </Offcanvas>
          }
          {isWide ?
            <div className='bigContainer'>
              <div>
                <NavContent />
                <IconFooter />
              </div>
              <div className='wide-content'>
                <MainContent />
              </div>
            </div>

            : <MainContent />

          }
        </div> :
        <Outlet/>

      }
    </>
  );
};

export default Layout;
