import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useUpdateUserMutation } from '../../redux/services/authApi';
import Subscribe from '../../components/klaviyo/subscribe/subscribe';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './account.css'
const Account = () => {
  const { t } = useTranslation("translation", {keyPrefix: "account"});
  const letsGo = useNavigate()
  const user = useSelector((state) => state?.auth?.user);
  const [formData, setFormData] = useState(user);
  const [updateUser, { isLoading, isSuccess, isError }] = useUpdateUserMutation();
  const [showMessage, setShowMessage] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [passwordData, setPasswordData] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const [passwordError, setPasswordError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowMessage(false);

    if (changePassword) {
      if (!validatePassword(passwordData.newPassword)) {
        setPasswordError(t("password.invalid", 'Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character.'));
        return;
      }
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        setPasswordError(t("password.doesNotMatch", 'Passwords do not match.'));
        return;
      }
    }

    setPasswordError(''); // Clear any previous password errors
    try {
      const updateData = {
        firstname: formData?.firstname,
        lastname: formData?.lastname,
        id: user?.id,
      };

      if (changePassword) {
        updateData.old_password = passwordData.currentPassword;
        updateData.password = passwordData.newPassword;
        updateData.confirm_password = passwordData.newPassword;
      }

      await updateUser(updateData);
      setShowMessage(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <h2 className="mt-4">{t("profile.user", "User Profile")}</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formFirstName">
              <Form.Label>{t("firstName.name", "First Name")}</Form.Label>
              <Form.Control
                type="text"
                name={t("firstName.name", "firstname")}
                value={formData?.firstname}
                onChange={handleChange}
                placeholder={t("firstName.placeholder", "Enter your first name")}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastName">
              <Form.Label>{t("lastName.name", "Last Name")}</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={formData?.lastname}
                onChange={handleChange}
                placeholder={t("lastName.placeholder", "Enter your last Name")}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>{t("email.email", "Email address")}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            disabled
            value={formData?.email}
            placeholder={t("email.placeholder", "Enter your email")}
          />
        </Form.Group>

        {/* Toggle for changing password */}
        <Form.Group className="mb-3">
          <Form.Check
            type="switch"
            label={t("password.password", "Change Password")}
            checked={changePassword}
            onChange={(e) => setChangePassword(e.target.checked)}
          />
        </Form.Group>

        {/* Password fields only visible when the toggle is active */}
        {changePassword && (
          <>
            <Form.Group className="mb-3" controlId="formCurrentPassword">
              <Form.Label>{t("password.current", "Current Password")}</Form.Label>
              <Form.Control
                type="password"
                name="currentPassword"
                value={passwordData.currentPassword}
                onChange={handlePasswordChange}
                placeholder={t("password.enterCurrent", "Enter your current password")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formNewPassword">
              <Form.Label>{t("password.new", "New Password")}</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
                placeholder={t("password.enterNew", "Enter your new password")}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formConfirmPassword">
              <Form.Label>{t("password.confirm", "Confirm Password")}</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                value={passwordData.confirmPassword}
                onChange={handlePasswordChange}
                placeholder={t("password.enterConfirmconfirm", "Confirm your new password")}
              />
            </Form.Group>

            {passwordError && (
              <Alert variant="danger">
                {passwordError}
              </Alert>
            )}
          </>
        )}

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : t("profile.updateButton", 'Update Profile')}
        </Button>
      </Form>

      <Button variant="primary" type="nav" onClick={() => {letsGo("/paymentMethods")}}>
          {t("profile.paymentMethods", 'View payment methods')}
        </Button>
      <Subscribe ts={new Date().getTime()}/>
      {showMessage && (
        <>
          {isSuccess && (
            <Alert variant="success" className="mt-3">
              {t("profile.success", "Profile updated successfully!")}
            </Alert>
          )}
          {isError && (
            <Alert variant="danger" className="mt-3">
              {t("profile.fail", "Failed to update profile. Please try again.")}
            </Alert>
          )}
        </>
      )}
    </Container>
  );
};

export default Account;
