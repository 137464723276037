import { useSelector, useDispatch } from 'react-redux';
import { getComponentsByName, addComponent, removeComponent, getSessionOptions } from '../../redux/slice/tanProfileSlice';
import React, { useState } from 'react';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import PopoverSelector from '../../components/popoverSelector/popoverSelector';
import { useTranslation } from 'react-i18next';
const ScentSelection = () => {
  const sessionOptions = useSelector(getSessionOptions)
    const { t } = useTranslation("translation", { keyPrefix: "scentSelection" });
    return (
    <>
    <NavigationWrapper>
      <div>
      {t("title", "Select Scent")}
      
      <PopoverSelector itemtype={"Scents"} amount={sessionOptions["WellFit"] ? 1 : 2} />
      </div>
      </NavigationWrapper>
    </>
  );
};

export default ScentSelection ;
