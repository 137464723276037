import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store/store'
import Router from './router/router';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.scss';
import languageInit from './i18n';

// import 'bootstrap/dist/css/bootstrap.min.css';


import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Loading = () => {
  console.log("loadingyeeet")
  return (
    <div>Loading...</div>
  );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
languageInit.then(() => {
  root.render(
    <React.StrictMode>
      <Elements stripe={stripePromise}>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <Router />
          </PersistGate>
        </Provider>
      </Elements>
    </React.StrictMode>
  );
})


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
