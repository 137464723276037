import React from 'react'
import "./circleButton.css"
function CircleButton(props) {

    return (
        <div onClick={() => props.onClick()} className="circle-btn" style={props.style}>
            <div className="circle-div">
            <div>{props.children}</div>
            {props.secondLine ? <div>{props.secondLine}</div> : null}
            </div>
    
        </div>
        );

}

export default CircleButton;