// src/redux/services/tanProfilesApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseUrl = process.env.REACT_APP_BASE_API_URL

export const tanHistoryApi = createApi({
  reducerPath: 'tanHistoryApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        // Get the user from the auth slice in the Redux store
        const user = getState().auth?.user;
  
        // If the user is logged in, add the token to the headers
        if (user?.token) {
          headers.set('authorization', `${user.token}`);
        }
  
        return headers;
      }, 
    }),
  endpoints: (builder) => ({
    getTanHistory: builder.query({
      query: (page) => ({
        url: `/session/customersession?page=${page.number}&limit=${page.limit}&status=${page.status}`,
        method: 'GET',
      }), // replace with your actual endpoint
    }),
  }),
});

export const { useGetTanHistoryQuery  } = tanHistoryApi;
