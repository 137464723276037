import React, { useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// import './tanSelection.css'; // Add custom CSS for styling
import { Container } from 'react-bootstrap';
import { useGetComponentsQuery } from '../../redux/services/tanProfilesApi';
import { getComponentsByName, addComponent, removeComponent, getSessionOptions } from '../../redux/slice/tanProfileSlice';



const Popover = ({ options, selected, onAddItem, onRemoveItem, text }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleAddItem = (item) => {
        onAddItem(item);
        handleClose();
    };

    return (
        <>
            <ListGroup>
                {selected?.map((item) => (
                    <ListGroup.Item key={item.id} className="d-flex align-items-center">
                        <div
                            style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                backgroundColor: item.color,
                                marginRight: '10px',
                            }}
                        />
                        <span style={{ flex: 1 }}>{item.value}</span>
                        <Button variant="success" onClick={() => onRemoveItem(item)}>
                            Remove
                        </Button>
                    </ListGroup.Item>
                ))}
                <ListGroup.Item>
                    <Button variant="primary" onClick={handleShow}>
                        {text?.buttonText || "Add New"}
                    </Button>
                </ListGroup.Item>
            </ListGroup>


            <Modal show={show} onHide={handleClose} fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>Select an Option</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {options?.map((item) => (
                            <ListGroup.Item key={item.id} className="d-flex align-items-center">
                                <div
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50%',
                                        backgroundColor: item.color,
                                        marginRight: '10px',
                                    }}
                                />
                                <span style={{ flex: 1 }}>{item.value}</span>
                                <Button variant="success" onClick={() => handleAddItem(item)}>
                                    +
                                </Button>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </>
    );
};

const PopoverSelector = ({itemtype, amount}) => {
    const dispatch = useDispatch();
    const componentData = useSelector((state) => getComponentsByName(state, itemtype)) || {};
    const profileData = useSelector((state) => state?.tanProfile?.selectedProfile?.session_component);  
    const sessionOptions = useSelector(getSessionOptions)
  
    const { error: componentError, isLoading: componentLoading, refetch: refetchComponents  } = useGetComponentsQuery({},{ skip: componentData && Object.keys(componentData).length });
    const max_selection = amount
    let selected = []
    for(let profileEntry in profileData){
      let complexItem = componentData.find((item) => item.id === profileData[profileEntry].component_value_id)
      console.log(complexItem)
      if(complexItem){
        selected.push(complexItem)
      }
    }
    const handleAddItem = (item) => {
      if(selected.length < max_selection){
        dispatch(addComponent(item))
        console.log('Added item:', item);
      }
    };
    const handleRemoveItem = (item) => {
      dispatch(removeComponent(item))
      console.log('removed item:', item);
    };
    return (
      <>
        <Popover options={componentData} selected={selected} onAddItem={handleAddItem} onRemoveItem={handleRemoveItem} />
      </>
    );
}

export default PopoverSelector;
