

import React, { useState } from 'react';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import PopoverSelector from '../../components/popoverSelector/popoverSelector';
import { useTranslation } from 'react-i18next';
const BronzerSelection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "bronzerSelection" });
    return (
    <>
      <NavigationWrapper>
        <div>
      {t("title", "Select Bronzer")}
          
          <PopoverSelector itemtype={"Bronzers"} amount={2} />
        </div>
      </NavigationWrapper>
    </>
  );
};

export default BronzerSelection;

