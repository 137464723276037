import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './tanSelection.css'; // Add custom CSS for styling
import { Container } from 'react-bootstrap';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import { setTan, getComponentById } from '../../redux/slice/tanProfileSlice';
import { useModifyImageMutation, useUploadImageMutation } from '../../redux/services/filterApi';
import { setUUID, setLoading } from '../../redux/slice/filterSlice';
import { v4 } from 'uuid'
import { upload } from '@testing-library/user-event/dist/upload';
import { useRef } from 'react';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';



const TanSelection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "tanSelection" });
  const display = useSelector((state) => state?.display?.display);
  const isWide = display.isWide
  const filterData = useSelector((state) => state?.filter);
  const tanLevel = useSelector((state) => getComponentById(state, 1))?.component_value_id;
  const dispatch = useDispatch()
  const [selectedCircle, setSelectedCircle] = useState(tanLevel);
  const [uploadImage] = useUploadImageMutation()
  const [modifyImage] = useModifyImageMutation()
  const uploadStarted = useRef(false);
  const chip_set = filterData.upload && JSON.parse(filterData.upload.chip_set)
  const avrage_colrtone_input_bgr = filterData.upload && JSON.parse(filterData.upload.avrage_colrtone_input_bgr)
  const modifiedUrlFull = filterData.modifiedUrl ? process.env.REACT_APP_BASE_FILTER_API_URL + filterData.modifiedUrl : null

  useEffect(() => {
    if (!uploadStarted.current && filterData.localImage && !filterData.upload && !filterData.imgUUID) {
      const uuid = v4();
      dispatch(setUUID(uuid));

      fetch(filterData.localImage)
        .then((res) => res.blob())
        .then((blob) => {
          const fileName = uuid + ".png"; // Specify your desired file name
          const file = new File([blob], fileName, { type: blob.type });
          const formData = new FormData();
          formData.append('file', file);
          uploadImage(formData);
          dispatch(setLoading(true))
        });

      uploadStarted.current = true; // Set flag to true after upload has started
    }
  }, [])
  useEffect(()=>{
    if(filterData.upload && !filterData.modifiedUrl && !filterData.loading){
      circleClicked(1)
    }
  },[filterData.loading])

  const buildModifyImage = (number) => {
    if(chip_set && avrage_colrtone_input_bgr){
      const index = number - 1
      const formData = new FormData();
      const isThisNeeded = (value) => { return value | 0}
      formData.append('Red', isThisNeeded(chip_set[index][0] - avrage_colrtone_input_bgr[0]));
      formData.append('Green', isThisNeeded(chip_set[index][1] - avrage_colrtone_input_bgr[1]));
      formData.append('Blue', isThisNeeded(chip_set[index][2] - avrage_colrtone_input_bgr[2]));
      formData.append('ImgPath', filterData.upload.image);
      formData.append('contrast', 0);
      formData.append('MaskPath', filterData.upload.mask_image);
      formData.append('uuid', filterData.upload.uid);
      formData.append('upload_id', filterData.upload.id);
      modifyImage(formData)
      dispatch(setLoading(true))
    }
  }

  const circleClicked = (number) => {
    setSelectedCircle(number)
    buildModifyImage(number)
    dispatch(setTan(number))
  }

  const renderCircle = (number) => {
    const isSelected = selectedCircle === number;
    let percent = (number * 100) / 12
    let remPercent = percent - 100;
    const circleSize = isWide ? "12vh" : "16vw"
    return (
      <div
        key={number}
        className={`circle ${isSelected ? 'selected' : ''}`}
        onClick={() => circleClicked(number)}
        style={{ background: "linear-gradient(0deg, #B88273 " + "0" + "%, white " + percent + "%, white 100%)", width: circleSize, height: circleSize }}
      >
        <span className="circle-number">{number}</span>
      </div>
    );
  };

  const imgSize = isWide ? "40vh" : "80vw"
  console.log(modifiedUrlFull)
  return (
    <>
      <NavigationWrapper>
        <Container>
          <div className="tanContainer">

            {(filterData.localImage || modifiedUrlFull) && (!filterData.loading ?
              <Image style={{ width: imgSize, }} src={modifiedUrlFull} rounded />
              : <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>)
            }
          </div>

          <div className="tanContainer">
            <div className="tanRow">
              {Array.from({ length: 4 }, (_, i) => renderCircle(i + 1))}
            </div>
            <div className="tanRow">
              {Array.from({ length: 4 }, (_, i) => renderCircle(i + 5))}
            </div>
            <div className="tanRow">
              {Array.from({ length: 4 }, (_, i) => renderCircle(i + 9))}
            </div>
          </div>
        </Container>
      </NavigationWrapper>
    </>
  );
};

export default TanSelection;
