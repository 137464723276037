import React from 'react';
import { useGetPaymentMethodsQuery } from '../../redux/services/paymentApi';
import SavedCardDisplay from '../../components/stripeCard/SavedCardDisplay';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Spinner, Alert } from 'react-bootstrap';

const PaymentMethod = () => {
  const { isLoading, error } = useGetPaymentMethodsQuery();
  const paymentMethods = useSelector((state) => state?.payment?.paymentMethods);

  return (
    <Container className="d-flex flex-column align-items-center">
      <h1 className="my-4">Payment Methods</h1>

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      {error && (
        <Alert variant="danger" className="text-center">
          Error loading payment methods: {error.message}
        </Alert>
      )}

      {!isLoading && !error && (
        <Row className="justify-content-center">
          {paymentMethods && paymentMethods.length > 0 ? (
            paymentMethods.map((method, index) => (
              <Col xs={12} md={6} lg={4} key={index} className="mb-4">
                <SavedCardDisplay card={method} />
              </Col>
            ))
          ) : (
            <Alert variant="info" className="text-center">
              No saved cards available.
            </Alert>
          )}
        </Row>
      )}
    </Container>
  );
};

export default PaymentMethod;
