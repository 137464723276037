const component_id = {
    "202202": {
        "name": "Base Tan Level",
        "slug": "base tan level",
        "sku": 202202,
        "componentValue": "12",
        "id": 12,
        "component_id": 1
    },
    "202203": {
        "name": "Activator",
        "slug": "activator",
        "sku": 202203,
        "componentValue": "Activator",
        "id": 13,
        "component_id": 2
    },
    "202204": {
        "name": "Moisturizer",
        "slug": "moisturizer",
        "sku": 202204,
        "componentValue": "no",
        "id": 25,
        "component_id": 3
    },
    "202205": {
        "name": "Cleanser",
        "slug": "cleanser",
        "sku": 202205,
        "componentValue": "Cleanser",
        "id": 15,
        "component_id": 4
    },
    "202206": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 202206,
        "componentValue": "Island",
        "id": 16,
        "component_id": 5
    },
    "202207": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 202207,
        "componentValue": "Mocha",
        "id": 17,
        "component_id": 5
    },
    "202208": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 202208,
        "componentValue": "Kona",
        "id": 18,
        "component_id": 5
    },
    "202209": {
        "name": "Scents",
        "slug": "scents",
        "sku": 202209,
        "componentValue": "Coconut Lime",
        "id": 19,
        "component_id": 6
    },
    "202210": {
        "name": "Scents",
        "slug": "scents",
        "sku": 202210,
        "componentValue": "Warm Vanilla",
        "id": 20,
        "component_id": 6
    },
    "202211": {
        "name": "Scents",
        "slug": "scents",
        "sku": 202211,
        "componentValue": "Fresh",
        "id": 21,
        "component_id": 6
    },
    "202212": {
        "name": "Scents",
        "slug": "scents",
        "sku": 202212,
        "componentValue": "Summer Dream",
        "id": 22,
        "component_id": 6
    },
    "202213": {
        "name": "Tan Accelerator",
        "slug": "accelerator",
        "sku": 202213,
        "componentValue": "no",
        "id": 24,
        "component_id": 7
    },
    "403368": {
        "name": "Scents",
        "slug": "scents",
        "sku": 403368,
        "componentValue": "Daydreamer_Test",
        "id": 30,
        "component_id": 6
    },
    "403369": {
        "name": "Scents",
        "slug": "scents",
        "sku": 403369,
        "componentValue": "Good Karma_Test",
        "id": 29,
        "component_id": 6
    },
    "403370": {
        "name": "Scents",
        "slug": "scents",
        "sku": 403370,
        "componentValue": "Vacay_Test",
        "id": 28,
        "component_id": 6
    },
    "403452": {
        "name": "Scents",
        "slug": "scents",
        "sku": 403452,
        "componentValue": "Cozy",
        "id": 35,
        "component_id": 6
    },
    "403722": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 403722,
        "componentValue": "Tuscan",
        "id": 37,
        "component_id": 5
    },
    "403725": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 403725,
        "componentValue": "Venetian",
        "id": 38,
        "component_id": 5
    },
    "403874": {
        "name": "WellFit",
        "slug": "Skincare Solutions",
        "sku": 403874,
        "componentValue": "Hydrate",
        "id": 65,
        "component_id": 23
    },
    "403875": {
        "name": "WellFit",
        "slug": "Skincare Solutions",
        "sku": 403875,
        "componentValue": "Lift",
        "id": 66,
        "component_id": 23
    },
    "403876": {
        "name": "WellFit",
        "slug": "Skincare Solutions",
        "sku": 403876,
        "componentValue": "Balance",
        "id": 67,
        "component_id": 23
    },
    "403888": {
        "name": "WellFit",
        "slug": "Skincare Solutions",
        "sku": 403888,
        "componentValue": "Recover",
        "id": 68,
        "component_id": 23
    },
    "403891": {
        "name": "Skincare Base",
        "slug": "WellFit Base Solution",
        "sku": 403891,
        "componentValue": "WellFit Base",
        "id": 64,
        "component_id": 22
    },
    "1235465": {
        "name": "Bronzers",
        "slug": "bronzers",
        "sku": 1235465,
        "componentValue": "403367",
        "id": 31,
        "component_id": 5
    }
}
const slice_map = () => {
    let arr = []
    for(let i in component_id){
        arr.push({
            sku: i,
            is_empty: 0,
            is_valid: 1,
    })
    }
    return arr
}
export {component_id, slice_map};