import React, { useState } from 'react';
import { Container, Form, Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { useCreateUserMutation } from '../../redux/services/authApi'; // Assuming you have a createUser endpoint in your API

const AccountCreation = () => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const [createUser, { isLoading, isSuccess, isError, error }] = useCreateUserMutation();
  const [validationError, setValidationError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValidationError('');

    if (!validatePassword(formData.password)) {
      setValidationError('Password must be at least 8 characters long, contain one uppercase letter, one number, and one special character.');
      return;
    }

    if (formData.password !== formData.confirm_password) {
      setValidationError('Passwords do not match.');
      return;
    }

    try {
      await createUser(formData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <h2 className="mt-4">Create Account</h2>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
                disabled={isSuccess || isLoading}
            />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="Enter your last name"
                required
            disabled={isSuccess || isLoading}
            />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email"
            required
            disabled={isSuccess || isLoading}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter your password"
            required
            disabled={isSuccess || isLoading}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            name="confirm_password"
            value={formData.confirm_password}
            onChange={handleChange}
            placeholder="Confirm your password"
            required
            disabled={isSuccess || isLoading}
          />
        </Form.Group>

        

        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : 'Create Account'}
        </Button>
      </Form>
      {validationError && (
          <Alert variant="danger">
            {validationError}
          </Alert>
        )}
      {isSuccess && (
        <Alert variant="success" className="mt-3">
          Account created successfully!
        </Alert>
      )}
      {isError && (
        <Alert variant="danger" className="mt-3">
            {error?.data?.msg}
        </Alert>
      )}
    </Container>
  );
};

export default AccountCreation;
