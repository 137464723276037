import React, { useState } from 'react';
import { Toast, Button, Modal } from 'react-bootstrap';
import './centeredToast.css';

const CenteredToast = ({
    show,
    onHide,
    onAccept,
    onCancel,
    title = 'Notification',
    body = 'This is a toast message',
    closeOnOverlayClick = false,
    children,
    acceptButtonText,
    cancelButtonText
}) => {
    const [showModal, setShowModal] = useState(show);

    const handleClose = () => {
        setShowModal(false);
        if (onHide) onHide();
    };

    const handleAccept = () => {
        if (onAccept) onAccept();
        handleClose();
    };

    const handleCancel = () => {
        if (onCancel) onCancel();
        handleClose();
    };

    const handleOverlayClick = () => {
        if (closeOnOverlayClick) {
            handleClose();
        }
    };

    return (
        <Modal
            show={showModal}
            onHide={handleClose}
            centered
            animation={false}
            backdrop={closeOnOverlayClick ? 'true' : 'static'}
            className="centered-toast-modal"
            dialogClassName="centered-toast-dialog"
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>{body}</h5>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleAccept} className="me-2">{acceptButtonText || "Accept"}</Button>
                <Button variant="secondary" onClick={handleCancel}>{cancelButtonText || "Cancel"}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CenteredToast;
