import React, { useEffect, useState } from 'react';
import './tanSelection.css'; // Add custom CSS for styling
import { Container } from 'react-bootstrap';
import SquareButton from '../../components/squareButton/squareButton';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setProfileMeta, setSelected, getSessionComponentsPretty, removeComponentsByName, setTypes } from '../../redux/slice/tanProfileSlice';
import { clearFilterData } from '../../redux/slice/filterSlice';
import { useLazyGetComponentsQuery } from '../../redux/services/tanProfilesApi';
import CenteredToast from '../../components/centeredToast/centeredToast';
import "./photoSelection.css"
import { getSessionOptions } from '../../redux/slice/tanProfileSlice';
import { slice_map } from '../../components/componentMap/componentMap';
import { clear } from '@testing-library/user-event/dist/clear';
const PhotoSelection = () => {
  const { t } = useTranslation("translation", { keyPrefix: "photoSelection" });
  const letsGo = useNavigate()
  const filterData = useSelector((state) => state?.filter)
  const displatch = useDispatch()
  return (
    <NavigationWrapper>
      <div className='button-holder'>
        <SquareButton className="" onClick={() => {
        displatch(clearFilterData())
        letsGo("/session/capturePhoto")
      }}>
        {t("selection.photo", "Photo")}
      </SquareButton>
      <SquareButton className="" onClick={() => {
        displatch(clearFilterData())
        letsGo("/session/tanSelection")
      }}>
        {t("selection.noPhoto", "No photo")}
      </SquareButton>
      </div>
    </NavigationWrapper>
  );
};

export default PhotoSelection;
