
import { createSlice } from '@reduxjs/toolkit';
import { paymentApi } from '../services/paymentApi';
const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    paymentMethods: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addMatcher(paymentApi.endpoints.getPaymentMethods.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        state.paymentMethods = payload?.payload; 
    })
    .addMatcher(
      paymentApi.endpoints.getPaymentMethods.matchRejected,
      (state, { error, meta }) => {
          state.error = error; // Store error in state
          state.paymentMethods = null; // Clear data on error
      });
  },
});

export default paymentSlice.reducer;
