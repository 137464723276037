import React, { useState, useRef, useEffect } from 'react';
import './capturePhoto.css'; // Add custom CSS for styling
import { Button, Container } from 'react-bootstrap';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLocalImage, clearFilterData } from '../../redux/slice/filterSlice';
const CapturePhoto = () => {
  const { t } = useTranslation("translation", { keyPrefix: "capturePhoto" });
  const filterData = useSelector((state) => state?.filter)
  // const [stream, setStream] = useState(null);
  const stream = useRef(null)
  // const [photo, setPhoto] = useState(filterData.localImage);
  const photo = filterData.localImage;
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const letsGo = useNavigate();
  const dispatch = useDispatch();

  // Start camera when component mounts
  useEffect(() => {
    startCamera();
    return () => {
      stopCamera(); // Stop the camera when component unmounts
    // setStream(null)
  };
  }, []);

  const startCamera = async () => {
    try {
      const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
      // setStream(stream);
      stream.current = newStream
      if (videoRef.current) {
        videoRef.current.srcObject = stream.current;
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = () => {
    if (stream.current) {
      stream.current.getTracks().forEach((track) => track.stop());
      stream.current = null
    }
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
      videoRef.current.srcObject = null
    }
  };

  const capturePhoto = () => {
    if (canvasRef.current && videoRef.current) {
      dispatch(clearFilterData())
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const context = canvas.getContext('2d');
      
      // Set canvas dimensions to match video dimensions
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Draw the current video frame on the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      
      // Set the photo data URL and stop the camera
      const imgData = canvas.toDataURL('image/png')
      // setPhoto(imgData);
      dispatch(setLocalImage(imgData))
      stopCamera();
    }
  };

  const retakePhoto = () => {
    // setPhoto(null);
    dispatch(clearFilterData())
    startCamera();
  };

  const usePhoto = () => {
    console.log("Photo confirmed:", photo);
    letsGo("/session/tanSelection"); // Redirect after using the photo
  };

  return (
    <NavigationWrapper onPrevious={() => {stopCamera()}} onNext={() => {stopCamera()}}>
      <Container className='photo-capture-container'>
        <video ref={videoRef} autoPlay playsInline className="camera-view" style={{display: photo ? "none" : ""}}></video>
        
        <canvas ref={canvasRef} className={`photo-preview`} style={{display: photo ? "" : "none"}}></canvas>
        
        {!photo ? (
          <Button onClick={capturePhoto} className="capture-button">
            {t("capture", "Capture Photo")}
          </Button>
        ) : (
          <div>
            <Button onClick={retakePhoto} className="retake-button">
              {t("retake", "Retake Photo")}
            </Button>
            <Button onClick={usePhoto} className="use-button">
              {t("use", "Use Photo")}
            </Button>
          </div>
        )}
      </Container>
    </NavigationWrapper>
  );
};

export default CapturePhoto;
