import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionComponentsPretty } from '../../redux/slice/tanProfileSlice';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import CenteredToast from '../../components/centeredToast/centeredToast';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { useSaveSessionProfileMutation, useSaveExistingSessionProfileMutation } from '../../redux/services/tanProfilesApi';
import "./confirmSelection.css"
import { useTranslation } from 'react-i18next';
const ConfirmSelection = () => {
    const items = useSelector(getSessionComponentsPretty)
    const [showAlert, setShowAlert] = useState(false)
    const [triggerNavigate, setTriggerNavigate] = useState(null)
    const selectedProfile = useSelector((state) => state?.tanProfile?.selectedProfile)
    const [profileName, setProfileName] = useState(selectedProfile?.session_name || "")
    const [saveSessionProfile, { isLoading, isSuccess, isError }] = useSaveSessionProfileMutation(); // RTK Query mutation hook
    const [saveExistingSessionProfile] = useSaveExistingSessionProfileMutation(); // RTK Query mutation hook
    const { t } = useTranslation("translation", { keyPrefix: "confirmSelection" });
    const handleSaveClick = async () => {
        if(profileName.length > 0){
            if(selectedProfile?.id){
                try {
                    const response = await saveExistingSessionProfile({
                        session_images: [{}],
                        session_name: profileName,
                        session_components: selectedProfile?.session_components,
                        id: selectedProfile.id
        
                    }).unwrap();
                    console.log('Profile saved successfully:', response);
                } catch (error) {
                    console.error('Failed to save profile:', error);
                }
            }else{
                try {
                    const response = await saveSessionProfile({
                        session_images: [{}],
                        session_name: profileName,
                        session_components: selectedProfile?.session_components
        
                    }).unwrap();
                    console.log('Profile saved successfully:', response);
                } catch (error) {
                    console.error('Failed to save profile:', error);
                }
            }
        }else{
            //boy should there be better validation responses huh
        }
    };

    return (
        <NavigationWrapper triggerNavigate={triggerNavigate} preventProceed={!triggerNavigate} onNext={() => setShowAlert(true)}>
            <ListGroup>
                <ListGroup.Item key="-1" className="d-flex align-items-center" style={{ background: "grey" }}>
                    <div className='itemLine'>
                        <span
                            style={{
                                backgroundColor: "black",
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                display: 'inline-block',
                                marginRight: '10px'
                            }}
                        ></span>
                        <div className='nameLine'>{t("list.solution", "Solution")}</div>
                        <div>{t("list.selection", "Selection")}</div>
                    </div>
                </ListGroup.Item>
                {items.map((item, index) => (
                    <ListGroup.Item key={index} className="d-flex align-items-center">
                        <div className='itemLine'>
                            <span
                                style={{
                                    backgroundColor: item.color,
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                    display: 'inline-block',
                                    marginRight: '10px'
                                }}
                            ></span>
                            <div className='nameLine'>{item.name}</div>
                            <div>{item.value}</div>
                        </div>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            {showAlert && <CenteredToast
                show={showAlert}
                onHide={(() => { setShowAlert(false) })}
                onAccept={() => { handleSaveClick() }}
                onCancel={() => { console.log("Cancel") }}
                title={t("alert.title", "Save Profile")}
                body={t("alert.body", "Would you like to save your session profile?")}
                acceptButtonText={t("alert.button", "Save")}
                closeOnOverlayClick={true}
            >
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1">{t("alert.inputTitle", "Profile Name")}</InputGroup.Text>
                    <Form.Control
                        placeholder={t("alert.placeholder", "Profile Name")}
                        aria-label={t("alert.label", "Profile Name")}
                        aria-describedby="basic-addon1"
                        value={profileName}
                        onChange={(data) => setProfileName(data.target.value)}
                    />
                </InputGroup>
            </CenteredToast>}

        </NavigationWrapper>
    );
};

export default ConfirmSelection;