import React from 'react';

const klaviyoHTML = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Klaviyo Form</title>
    <script src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=VCHfYC" async></script>
</head>
<body>
    <div class="klaviyo-form-VDTwcr"></div>
    <script>
        // window.onload = function() {
        //     window._klOnsite = window._klOnsite || [];
        //     window._klOnsite.push(['openForm', 'VDTwcr']);
        // };
    </script>
</body>
</html>
`;

const Subscribe = () => {
  // Encode the HTML content as a data URL
  const iframeSrc = `data:text/html;charset=utf-8,${encodeURIComponent(klaviyoHTML)}`;

  return (
    <iframe
      className='klaviyoFrame'
      src={iframeSrc}
      style={{ width: '100%', height: '120px', border: 'none' }} // Adjust dimensions as needed
      title="Klaviyo Form"
    ></iframe>
  );
};

export default Subscribe;
