import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function SavedCardDisplay({ card }) {
  const { t } = useTranslation("translation", {keyPrefix: "savedCardDisplay"});
  if (!card) return null;

  return (
    <Card style={{ width: '18rem', marginBottom: '1rem' }}>
      <Card.Body>
        {/* <Card.Title>Saved Credit Card</Card.Title> */}
        <Card.Text>
          <strong>{t("card.brand", 'Card Brand: ')}</strong> {card.brand}
        </Card.Text>
        <Card.Text>
          <strong>{t("card.number", 'Card: ')}</strong>{t("card.stars", ' **** **** **** ')}{card.last4}
        </Card.Text>
        <Card.Text>
          <strong>{t("card.expires", 'Expires: ')}</strong> {card.exp_month}/{card.exp_year}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default SavedCardDisplay;