import React from 'react';
import { Button } from 'react-bootstrap'; // Optional: If you're using React Bootstrap
import './navigationWrapper.css'; // Import the CSS file
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSessionOptions } from '../../redux/slice/tanProfileSlice';

const NavigationWrapper = ({ onPrevious, onNext, preventProceed, triggerNavigate,  children }) => {
  const { t } = useTranslation("translation", { keyPrefix: "navigationWrapper" });
  const letsGo = useNavigate()
  const location = useLocation()
  const tanProfile = useSelector((state) => state?.tanProfile)
  const sessionTypes = tanProfile?.profileMeta?.sessionTypes
  const sessionOptions = useSelector(getSessionOptions)
  // const sessionOptions = {}
  const locationParts = {
    segment1: location.pathname.split('/')[1],
    segment2: location.pathname.split('/')[2]
  }
  const sessionSelection = locationParts?.segment2 !== "sessionSelection"
  // just defines what order the routes are in.
  // could probably just determine it based on what's available 
  // in the moment but I like this so leave me alone.
  let navOptions = [
    { segment: "scanQR", nav: false },
    { segment: "sessionSelection", nav: false },
    { segment: "photoSelection", nav: false },
    { segment: "capturePhoto", nav: false },
    { segment: "tanSelection", nav: false },
    { segment: "WellFit", nav: false },
    { segment: "moisturizer", nav: false },
    { segment: "bronzers", nav: false },
    { segment: "scents", nav: false },
    { segment: "accelerator", nav: false },
    { segment: "confirmSession", nav: true },
  ]
  const navigate = (forward) => {
    const currentIndex = navOptions.findIndex(option => option.segment === locationParts.segment2)
    const navHelper = (i) => {
      if (navOptions[i].nav) {
        letsGo(`/${locationParts.segment1}/${navOptions[i].segment}`)
        return true
      }
    }
    if (forward) {
      for (let i = currentIndex + 1; i < navOptions.length; i++) {
        if (navHelper(i)) {
          break
        }
      }
    } else {
      for (let i = currentIndex - 1; i > -1; i--) {
        if (navHelper(i)) {
          break
        }
      }
    }
  }
  // Logic for where you can navigate
  switch (tanProfile?.profileMeta?.profileType) {
    case "scanBooth":
      navOptions.find(option => option.segment === "scanQR").nav = true;
      break;
    case "edit":
      navOptions.find(option => option.segment === "sessionSelection").nav = true;
      break;
    case "new":
      navOptions.find(option => option.segment === "sessionSelection").nav = true;
      break;
    case "booth":
      navOptions.find(option => option.segment === "sessionSelection").nav = true;
      break;
    default:
      break;
  }
  //tanProfileSlice has a selector that determines the viability of the sessions. felt better there.
  if(sessionOptions["Tan"] && sessionTypes?.includes("tan")){
    navOptions.find(option => option.segment === "tanSelection").nav = true;
    navOptions.find(option => option.segment === "photoSelection").nav = true;
    if(sessionOptions["Moisturizer"]){
      navOptions.find(option => option.segment === "moisturizer").nav = true;
    }
    if(sessionOptions["Bronzer"]){
      navOptions.find(option => option.segment === "bronzers").nav = true;
    }
    if(sessionOptions["Scent"]){
      navOptions.find(option => option.segment === "scents").nav = true;
    }
    if(sessionOptions["Accelerator"]){
      navOptions.find(option => option.segment === "accelerator").nav = true;
    }
  }
  if(sessionOptions["WellFit"] && sessionTypes?.includes("wellfit")){
    navOptions.find(option => option.segment === "WellFit").nav = true;
    if(sessionOptions["Scent"]){
      navOptions.find(option => option.segment === "scents").nav = true;
    }
  }
  if(triggerNavigate){
    if(triggerNavigate === "next"){
      navigate(true)
    }else{
      navigate(false)
    }
  }
  return (
    <>
      <div className='nav-wrapper'>
        {children}
      </div>
      {sessionSelection && <footer className="footer">
        <Button variant="primary" onClick={() => {
          onPrevious && onPrevious()
          navigate(false)
        }} className="footer-button">
          {t("buttons.previous", "Previous")}
        </Button>
        <Button variant="primary" onClick={() => {
          onNext && onNext();
          !preventProceed && navigate(true)
        }} className="footer-button">
          {t("buttons.next", "Next")}
        </Button>
      </footer>}
    </>

  );
};

export default NavigationWrapper;
