import React, { useState } from 'react';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import PopoverSelector from '../../components/popoverSelector/popoverSelector';
import { useTranslation } from 'react-i18next';
const MoisturizerSelection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "moisturizerSelection" });
    return (
    <>
      <NavigationWrapper>
        <div>
      {t("title", "Select Moisturizer")}
          <PopoverSelector itemtype={"Moisturizer"} amount={1} />
        </div>
      </NavigationWrapper>
    </>
  );
};

export default MoisturizerSelection;
