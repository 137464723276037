// src/components/Login.js
import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLoginMutation } from '../../redux/services/authApi';
import { useTranslation } from 'react-i18next';

const Login = () => {
  const { t } = useTranslation("translation", {keyPrefix: "login"});
  const user = useSelector((state) => state?.auth?.user)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [login, { isLoading, isSuccess, error }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await login({ email, password }).unwrap();
      // dispatch(setUser(result));
    } catch (err) {
      console.error('Login failed:', err);
    }
  };

  useEffect(() => {
    if(isSuccess){
      // navigate("/account")
      // setTimeout(() => navigate("/account"), 1)
    }
  }, [isSuccess])

  return (
    <div className="container">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formEmail">
          <Form.Label>{t("email.label","Email address")}</Form.Label>
          <Form.Control
            type="email"
            placeholder={t("email.placeholder", "Enter email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            disabled={isSuccess}
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>{t("password.label", "Password")}</Form.Label>
          <Form.Control
            type="password"
            placeholder={t("password.placeholder", "Password")}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled={isSuccess}
          />
        </Form.Group>
        <Button variant="primary" type="submit" disabled={isLoading}>
          {isLoading ? <Spinner animation="border" size="sm" /> : t("button.login", 'Login')}
        </Button>
        <Button variant="primary" type="submit" onClick={(e) => {
          e.preventDefault();
          navigate("/accountCreate")
        }} >
          {t("button.new", "New Account")}
        </Button>
        
        {error && <Alert variant="danger">{t("alert.fail", "Login failed!")}</Alert>}
        {isSuccess && <Alert variant="success">{t("alert.success", "Successfully logged in!")}</Alert>}
      </Form>
    </div>
  );
};

export default Login;