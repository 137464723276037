// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { api } from '../services/authApi';

const authSlice = createSlice({
  name: 'auth',
  initialState: { 
    user: null, 
    status: 'idle',
    updated: 0
  },
  reducers: {
    logout: (state, action) => {
        state.user = null;
      },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload, dispatch }) => {
        state.user = payload.payload; // yucky
        // dispatch(setUser(payload.payload))
      })
      .addMatcher(api.endpoints.createUser.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        // state.user = payload.payload; // yucky
      })
      .addMatcher(api.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        // state.user = payload.payload; // yucky
      })
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;