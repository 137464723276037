import React, { useEffect, useState, useRef } from 'react';
import { Container, ListGroup, Spinner, Alert, Badge, Pagination } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetTanProfilesQuery, useGetComponentsQuery, useLazyGetComponentsQuery, useLazyGetTanProfilesQuery } from '../../redux/services/tanProfilesApi';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileMeta, setSelected } from '../../redux/slice/tanProfileSlice';
import { useTranslation } from 'react-i18next';
import "./tanProfiles.css";
import {slice_map} from '../../components/componentMap/componentMap';
const TanProfiles = () => {
  const { t } = useTranslation("translation", { keyPrefix: "tanProfiles" });
  const itemsPerPage = 4; // Number of items per page
  const [page, setPage] = useState(1); // Current page, starts from 1
  const prevPageRef = useRef();
  const profileData = useSelector((state) => state?.tanProfile?.profiles);
  const componentData = useSelector((state) => state?.tanProfile?.components);
  const currentProfiles = profileData[page]?.users;

  // Check if we already have profile data for the current page
  // const shouldFetchProfiles = !profileData[page];

  const [getComponents, { isLoading, isSuccess, isError }] = useLazyGetComponentsQuery();
  const [getTanProfiles] = useLazyGetTanProfilesQuery();
  // // Fetch profiles conditionally (only when not already fetched)
  // const { error: profileError, isLoading: profileLoading, refetch: refetchProfiles } = useGetTanProfilesQuery(
  //   { page: page, limit: itemsPerPage },
  //   // { skip: profileData[page] } // Only run query if no data for this page
  // );
  // const { error: componentError, isLoading: componentLoading, refetch: refetchComponents  } = useGetComponentsQuery({},{  });

  // Always fetch components once

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const totalProfiles = profileData[page]?.total || 0; // Total number of profiles from API
  const totalPages = Math.ceil(totalProfiles / itemsPerPage); // Calculate total pages based on total profiles
  useEffect(()=>{
    prevPageRef.page = page
    getComponents()
    getTanProfiles({ page: page, limit: itemsPerPage })
  },[])
  useEffect(()=>{
    if(prevPageRef?.page !== page){
      getComponents()
      getTanProfiles({ page: page, limit: itemsPerPage })
    }
  // console.log(page)
  // console.log(componentData)
  // console.log(profileData[page])
  },[page])
  // Handle page change
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  if (!profileData[page] || !componentData) {
    return <Spinner animation="border" />;
  }

  // if (profileError || componentError) {
  //   return <Alert variant="danger">Error fetching data</Alert>;
  // }

  return (
    <Container className="mt-4 profiles-main">
      <ListGroup>
        {currentProfiles && Object.keys(currentProfiles).map((i) => (
          <ListGroup.Item
            key={currentProfiles[i]?.id}
            action
            onClick={() => {
              dispatch(setProfileMeta({profileType: "edit", options: slice_map()}))
              dispatch(setSelected({
                // session_images: [{}],
                session_name: currentProfiles[i]?.data.session_name,
                session_component: currentProfiles[i]?.session_components,
                id: currentProfiles[i]?.id
            }))
              navigate(`/session/sessionSelection`);
            }}
            style={{ fontSize: '1.8rem' }}
          >
            <div className="ms-2 me-auto">
              <div className="fw-bold">{currentProfiles[i]?.data.session_name}</div>
              <h5>
                {Object.keys(currentProfiles[i]?.session_components).length > 0 && componentData && Object.keys(currentProfiles[i]?.session_components).map((j) => {
                  let string = "";
                  let component_id = currentProfiles[i]?.session_components[j]?.component_id;
                  let component_value = currentProfiles[i]?.session_components[j]?.value;
                  let name = componentData[component_id]?.name;
                  if (name) {
                    string += name + ": ";
                  }
                  if (component_value) {
                    string += component_value;
                  }
                  return (
                    <Badge bg="primary" pill>
                      {string}
                    </Badge>
                  );
                })}
              </h5>
            </div>
          </ListGroup.Item>
        ))}
        <ListGroup.Item
          action
          onClick={() => {
            dispatch(setProfileMeta({profileType: "new", options: slice_map()}))
            navigate(`/session/sessionSelection`);
          }}
          style={{ fontSize: '1.8rem' }}
        >
          <div className="fw-bold ms-2 me-auto">
            <i className="bi bi-plus-circle-fill"></i> {t("button.addnew", "Add New Profile")}
          </div>
        </ListGroup.Item>
      </ListGroup>

      {/* Pagination Controls */}
      <Pagination className="mt-3 pagination">
        <Pagination.First onClick={() => handlePageChange(1)} disabled={page === 1} />
        <Pagination.Prev onClick={() => handlePageChange(page - 1)} disabled={page === 1} />
        {Array.from({ length: totalPages }, (_, i) => (
          <Pagination.Item key={i + 1} active={i + 1 === page} onClick={() => handlePageChange(i + 1)}>
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next onClick={() => handlePageChange(page + 1)} disabled={page === totalPages} />
        <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={page === totalPages} />
      </Pagination>
    </Container>
  );
};

export default TanProfiles;
