import React from "react";
import { useNavigate } from 'react-router-dom';
import "./landing.css";
import SquareButton from "../../components/squareButton/squareButton";
const App = () => {
  const letsGo = useNavigate();
  return (
    <div className="landing-container">
      <div className="landing-content">
        <h1 className="landing-text">Sign up or login to start customizing your spray tan</h1>
        <div className="landing-buttons">
          <SquareButton className='landing-btn-left' onClick={() => letsGo("/login")}>LOGIN</SquareButton>
          <SquareButton className='landing-btn-right' onClick={() => letsGo("/accountcreate")}>SIGN UP</SquareButton>
        </div>
      </div>
    </div>
  );
};

export default App;
