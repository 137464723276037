import React, { useState } from 'react';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import PopoverSelector from '../../components/popoverSelector/popoverSelector';
import { useTranslation } from 'react-i18next';
const WellfitSelection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "wellfitSelection" });
    return (
    <>
      <NavigationWrapper>
        <div>
      {t("title", "Select Wellfit")}
          
          <PopoverSelector itemtype={"WellFit"} amount={2} />
        </div>
      </NavigationWrapper>
    </>
  );
};

export default WellfitSelection;

