// src/redux/slice/tanProfilesSlice.js
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { tanProfilesApi } from '../services/tanProfilesApi';
// import { component_id } from '../../components/componentMap/componentMap';

const tanProfilesSlice = createSlice({
  name: 'tanProfiles',
  initialState: {
    profiles: {},
    components: {},
    selectedProfile: {
      session_component: []
    },
    mappedComponents: {},
    profileMeta: {
      profileType: null, //edit, new, booth, scanBooth
      sessionTypes: [], // tan, wellfit
      options: [    ] //just an array of solution options, can be from booth inv
    } //just a catchall for profile stuff, used for defining the type of profile edit/selection occuring
  },
  reducers: {
    setSelected: (state, action) => {
      state.selectedProfile = action.payload;
    },
    setProfileMeta: (state, action) => {
      state.profileMeta = action.payload;
    },
    setTypes: (state, action) => {
      state.profileMeta.sessionTypes = action.payload;
    },
    removeComponent: (state, action) => {
      let currentComponents = state.selectedProfile.session_component

      const indexToRemove = currentComponents.findIndex(
        item => item.component_id === action.payload.component_id && item.component_value_id === action.payload.id
      );
      if (indexToRemove !== -1) {
        currentComponents.splice(indexToRemove, 1);
      }
      state.selectedProfile.session_component = currentComponents;
    },
    removeComponentsByName: (state, action) => {
      let currentComponents = state.selectedProfile.session_component
      let components = state.components
      console.log(components)
      const  id = Object.values(components).find((item) => item.name === action.payload).id

      let indexToRemove = currentComponents.findIndex(item => item.component_id === id);
      while (indexToRemove > -1) {
        currentComponents.splice(indexToRemove, 1);
        indexToRemove = currentComponents.findIndex(item => item.component_id === id);
      }

      state.selectedProfile.session_component = currentComponents;
    },
    addComponent: (state, action) => {
      let currentComponents = state.selectedProfile.session_component
      currentComponents.push({
        "value": action.payload.value,
        "component_id": action.payload.component_id,
        "component_value_id": action.payload.id
      })
      state.selectedProfile.session_component = currentComponents;
    },
    setTan: (state, action) => {
      let currentComponents = state.selectedProfile.session_component
      const existingTanIndex = currentComponents.findIndex((item) => item.component_id === 1) //Don't @ me nerd
      const newLevel = {
        "value": action.payload.toString(),
        "component_id": 1,
        "component_value_id": action.payload
      }
      if(existingTanIndex > -1){
        currentComponents[existingTanIndex] = newLevel
      }else{
        currentComponents.push(newLevel)
      }
      state.selectedProfile.session_component = currentComponents;
    },
  },
  extraReducers: (builder) => {
    builder
    .addMatcher(tanProfilesApi.endpoints.getTanProfiles.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        state.profiles[payload?.payload?.getAllProfileByUser?.current_page] = payload?.payload?.getAllProfileByUser; 
    })
    .addMatcher(tanProfilesApi.endpoints.getComponents.matchFulfilled, (state, { payload }) => {
        console.log(payload)
        const timeStamp = new Date().getTime()
        let  comps = payload?.payload?.components.reduce((acc, item) => {
          acc[item.id] = item;
          return acc;
        }, {}); 
        let obj = {}
        for(let product in comps){
          for(let component in comps[product].component_values){
            obj[comps[product].component_values[component].sku] = {
              name: comps[product].name, 
              slug: comps[product].slug, 
              // max: comps[product].max, 
              sku: comps[product].component_values[component].sku,
              componentValue: comps[product].component_values[component].value,
              id: comps[product].component_values[component].id,
              componentId: comps[product].component_values[component].component_id,
            }
          }
        }
        comps.timeStamp = timeStamp
        obj.timeStamp = timeStamp
        state.components = comps
        state.mappedComponents = obj
    })
  },
});

export const getSessionOptions = createSelector(
  [(state) => state?.tanProfile],
  (tanProfile) => {
    const options = tanProfile?.profileMeta?.options
    let names = [];
    for (let i in options) {
      let option = tanProfile?.mappedComponents[options[i].sku];
      if (option && !options[i].is_empty && options[i].is_valid) {
        names.push(option.name);
      }
    }
    return {
      Tan: names.includes("Base Tan Level") && (names.includes("Activator") || names.includes("Developer")),
      WellFit: names.includes("WellFit") && names.includes("Skincare Base"),
      Moisturizer: names.includes("Moisturizer"),
      Bronzer: names.includes("Bronzers"),
      Scent: names.includes("Scents"),
      Accelerator: names.includes("Tan Accelerator"),
    };
  }
);

export const getComponentsByName = createSelector(
  [(state) => state?.tanProfile?.components, (_, name) => name],  // First is state, second is the name parameter
  (components, name) => {
    return Object.values(components).find(item => item.name === name)?.component_values;;
  }
);

export const getComponentById = createSelector(
  [(state) => state?.tanProfile?.selectedProfile?.session_component, (_, id) => id],  // First is state, second is the name parameter
  (components, id) => {
    return components.find((item) => item.component_id === id);
  }
);

export const getSessionComponentsPretty = createSelector(
  [(state) => state?.tanProfile, (_, id) => id],  // First is state, second is the name parameter
  (tanProfile, id) => {
    let compArr = []
    for(let i in tanProfile?.components){
      for(let component in tanProfile?.components[i].component_values){
        let component_id = tanProfile?.components[i].component_values[component].id
        if(tanProfile?.selectedProfile?.session_component.find((item) => item.component_value_id === component_id))
        compArr.push({...tanProfile?.components[i].component_values[component], name: tanProfile?.components[i].name})
      }
    }
    return compArr;
  }
);

export const { setSelected, setProfileMeta, addComponent, removeComponent, setTan, setTypes, removeComponentsByName } = tanProfilesSlice.actions;
export default tanProfilesSlice.reducer;
