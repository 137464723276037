import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';

const languageInit = i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(resourcesToBackend((language, namespace) => import(`./public/locales/${language}/${namespace}.json`)))
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next"
    //     }
    //   }
    // },
    lng: "en-US", // if you're using a language detector, do not define the lng option
    fallbackLng: ["en-US", "en", "fr"],

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

  export default languageInit;
