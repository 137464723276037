// src/redux/services/tanProfilesApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// const baseUrl = process.env.REACT_APP_BASE_FILTER_API_URL
const baseUrl = process.env.REACT_APP_BASE_API_URL

export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({ 
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        // Get the user from the auth slice in the Redux store
        const user = getState().auth?.user;
  
        // If the user is logged in, add the token to the headers
        if (user?.token) {
          headers.set('authorization', `${user.token}`);
        }
  
        return headers;
      }, 
    }),
  endpoints: (builder) => ({
    getPaymentMethods: builder.query({
      query: (body) => ({
        url: `payment_methods`,
        method: 'GET',
        // body: body
      }), 
    }),
    // modifyImage: builder.mutation({
    //   query: (body) => ({
    //     url: `modified_image`,
    //     method: 'POST',
    //     body: body
    //   }), // replace with your actual endpoint
    // }),
  }),
});

export const { useGetPaymentMethodsQuery } = paymentApi;
