import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Provider, useSelector, useDispatch } from 'react-redux'

import App from '../pages/app/app';
import TanProfiles from "../pages/profiles/tanProfiles";
import Account from '../pages/account/account';
import AccountCreation from '../pages/account/accountCreation';
import Login from '../pages/login/login';
import History from '../pages/history/history';
import Landing from "../pages/landing/landing"
import Layout from "../layout/layout"
import TanSelection from "../pages/sessionProfileSetup/tanSelection";
import Greeting from "../pages/greeting/greeting";
import SessionSelection from "../pages/sessionProfileSetup/sessionSelection";
import WellfitSelection from "../pages/sessionProfileSetup/wellfitSelection";
import MoisturizerSelection from "../pages/sessionProfileSetup/moisturizerSelection";
import ScentSelection from "../pages/sessionProfileSetup/scentSelection";
import AcceleratorSelection from "../pages/sessionProfileSetup/acceleratorSelection";
import BronzerSelection from "../pages/sessionProfileSetup/bronzerSelection";
import ConfirmSelection from "../pages/sessionProfileSetup/confirmSelection";
import PhotoSelection from "../pages/sessionProfileSetup/photoSelection";
import CapturePhoto from "../pages/sessionProfileSetup/capturePhoto";
import PaymentMethods from "../pages/account/paymentMethods";
const Router = () => {
    // const state = useSelector((state) => state)
    // console.log(state) // helps to see if stuff gets stuck re-rendering, and to view allstate. Insurance if you wil
    const user = useSelector((state) => state?.auth?.user)
    const CheckMultipleRoutes = (routes) => {
        let routeArray = []
        for(let i in routes){
            routeArray.push(<Route key={routes[i].path} path={routes[i].path} element={user ? routes[i].page : <Navigate to="/landing" />} />)
        }
        return routeArray
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index key="index" element={user ? <Greeting/> : <Navigate to="/landing" />} />
                    <Route key="login" path="login" element={user ? <Navigate to="/greeting" /> : <Login/>} />
                    <Route key="accountcreate" path="accountcreate" element={<AccountCreation />} />
                    <Route key="landing" path="landing" element={<Landing />} />
                    {/* <Route path="account" element={<Account />} /> */}
                    {CheckMultipleRoutes([
                        {path: "account", page: <Account />},
                        {path: "paymentMethods", page: <PaymentMethods />},
                        {path: "greeting", page: <Greeting />},
                        {path: "tanProfiles", page: <TanProfiles />},
                        {path: "history", page: <History />},
                        {path: "session/sessionSelection", page: <SessionSelection />},
                        {path: "session/photoSelection", page: <PhotoSelection />},
                        {path: "session/capturePhoto", page: <CapturePhoto />},
                        {path: "session/tanSelection", page: <TanSelection />},
                        {path: "session/wellfit", page: <WellfitSelection/>},
                        {path: "session/moisturizer", page: <MoisturizerSelection/>},
                        {path: "session/bronzers", page:<BronzerSelection/>},
                        {path: "session/scents", page: <ScentSelection/>},
                        {path: "session/accelerator", page: <AcceleratorSelection/>},
                        {path: "session/confirmSession", page: <ConfirmSelection/>},
                    ])}
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )

}

export default Router