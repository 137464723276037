
import React, { useState } from 'react';
import NavigationWrapper from '../../components/sessionNavigation/navigationWrapper';
import PopoverSelector from '../../components/popoverSelector/popoverSelector';
import { useTranslation } from 'react-i18next';
const AcceleratorSelection = () => {
    const { t } = useTranslation("translation", { keyPrefix: "acceleratorSelection" });
    return (
    <>
      <NavigationWrapper>
        <div>
        {t("title", "Choose Accelerator")}
          <PopoverSelector itemtype={"Tan Accelerator"} amount={2} />
        </div>
      </NavigationWrapper>
    </>
  );
};

export default AcceleratorSelection;