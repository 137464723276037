import React from 'react';
import './greeting.css';
import SquareButton from '../../components/squareButton/squareButton';
import GreetingCarousel from '../../components/greetingCarousel/greetingCarousel';
import Carousel from 'react-bootstrap/Carousel';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Subscribe from '../../components/klaviyo/subscribe/subscribe';
import CircleButton from '../../components/circleButton/circleButton';
import { useNavigate } from 'react-router-dom';
import { Suspense } from 'react';
import { setProfileMeta } from '../../redux/slice/tanProfileSlice';
import {slice_map} from '../../components/componentMap/componentMap';
function Greeting() {
    // return(<>test</>)
    const user = useSelector((state) => state?.auth?.user);
    const display = useSelector((state) => state?.display?.display);
    const dispatch = useDispatch();
    // const user = "me"
    // const display = {width: 50, height: 40, isWide:false}
    const { t } = useTranslation("translation", { keyPrefix: "greeting" });
    const letsGo = useNavigate();//
    let name = user?.firstname || "friend"
    if (display?.isWide) {
        document.documentElement.style.setProperty('--greeting-container-size', "40vh")
    } else {
        document.documentElement.style.setProperty('--greeting-container-size', "100vw")
    }
    // return(
    // <Suspense fallback={<>fallback</>}><>Data</></Suspense>)
    return (
        <>
            <div>
                <div class="greeting-container">
                    <div class="text-right">
                        {t("welcome.hello", "Hi, ")}{name}!
                    </div>
                    <SquareButton className="button-left greeting-button" onClick={() => {
                            letsGo("/session/sessionSelection")
                        }}>
                        <div className='button-text'>{t("start.new", "Start a new")}</div>
                        <div className='button-text'>{t("start.session", "Tanning session")}</div>
                    </SquareButton>
                </div>
                <GreetingCarousel />
                <div class="greeting-container container-two"></div>
                <div className="full-button-container">
                    <SquareButton className="full-button full-button-one">
                        {t("carousel.tryon", "Spray tan try on")}
                    </SquareButton>
                </div>
                <Carousel className='image-carousel' data-bs-theme="dark">
                    <Carousel.Item className='image-carousel-item'>
                        <div className='image-carousel-image'>
                            {t("carousel.findLocation", "Find a location")}
                        </div>
                        <div className='image-carousel-image'>
                            {t("carousel.history", "Your spray tan history")}
                        </div>
                    </Carousel.Item>
                </Carousel>
                <div class="greeting-container container-three"></div>
                <div className="full-button-container">
                    <SquareButton className="full-button full-button-two">
                        {t("carousel.buildTan", "Build a custom tan")}
                    </SquareButton>
                </div>
                <div class="greeting-container container-footer">
                    <div class="footer-header">
                        <div>{t("footerheader.unity", "Unity")}</div>
                        <div>
                            <CircleButton onClick={() => { window.open("https://www.instagram.com/mystictan/", '_blank').focus() }}>
                                <i class="bi bi-instagram"></i>
                            </CircleButton>
                            <CircleButton onClick={() => { window.open("https://www.tiktok.com/@mystictan_official", '_blank').focus() }}>
                                <i class="bi bi-tiktok"></i>
                            </CircleButton>
                        </div>
                    </div>
                    <div className="footer-links">
                        <a onClick={() => letsGo("/")}>{t("footerlink.about", "About")}</a>
                        <a onClick={() => { window.open("https://sunless.com/pages/salon-locator", '_blank').focus() }}>{t("footerlink.findlocation", "Find a location")}</a>
                        <a onClick={() => { window.open("https://sunless.com/", '_blank').focus() }}>{t("footerlink.shop", "shop")}</a>
                    </div>
                    <div className='footer-slogan'>{t("footerslogan.words", "The future is customizeable.")}</div>
                    <Subscribe />
                </div>
            </div>
        </>
    );
}

export default Greeting;
