// src/redux/slice/tanProfilesSlice.js
import { createSlice } from '@reduxjs/toolkit';

const displaySlice = createSlice({
  name: 'displaySlice',
  initialState: {
    display: {
      width: 0,
      height: 0,
      isWide: false
    },
    sidebar:{
      open: false
    }
  },
  reducers: {
    setDisplay: (state, action) => {
      state.display = action.payload;
    },
    setSidebarOpen: (state, action) => {
      state.sidebar.open = action.payload;
    },
  },
});

export const { setDisplay, setSidebarOpen } = displaySlice.actions;
export default displaySlice.reducer;
