// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const env = process.env
const baseUrl = process.env.REACT_APP_BASE_API_URL
console.log("connection url: " + baseUrl)
console.log(env)
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Get the user from the auth slice in the Redux store
      const user = getState().auth?.user;

      // If the user is logged in, add the token to the headers
      if (user?.token) {
        headers.set('authorization', `${user.token}`);
      }

      return headers;
    }, 
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: 'users/customer/login',
        method: 'POST',
        body: credentials,
      }),
      // async onQueryStarted(id, {dispatch, queryFulfilled}){
      //   try {
      //     const {data} = await queryFulfilled
          
      //     dispatch(setUser(data.payload))
      //   } catch (err){
      //     console.log(err)
      //   }
      // }
    }),
    updateUser: builder.mutation({
      query: (body) => ({
        url: 'users/account-setting',
        method: 'PUT',
        body: body,
      }),
    }),
    createUser: builder.mutation({
      query: (userData) => ({
        url: 'users/customer/create', // adjust to your API endpoint for user creation
        method: 'POST',
        body: userData,
      }),
    }),
  }),
});

export const { useLoginMutation, useUpdateUserMutation, useCreateUserMutation } = api;