// src/components/TanProfiles.js
import React, { useState, useEffect } from 'react';
import { Container, ListGroup, Spinner, Alert, Badge, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useGetTanProfilesQuery, useGetComponentsQuery } from '../../redux/services/tanProfilesApi';
import { useGetTanHistoryQuery } from '../../redux/services/historyApi';
import { useDispatch, useSelector } from 'react-redux';

const TanProfiles = () => {
  const profileData = useSelector((state) => state?.tanProfile?.profiles);
  const componentData = useSelector((state) => state?.tanProfile?.components);
  const history = useSelector((state) => state?.history?.data);

  const [currentPage, setCurrentPage] = useState(1);
  const { error: historyError, isLoading: historyLoading } = useGetTanHistoryQuery({page:{
    number: currentPage,
    limit: 1, 
    status:4
  }}, {skip: history});
  const { error: profileError, isLoading: profileLoading } = useGetTanProfilesQuery({ page: 1, limit:999 });
  const { error: componentError, isLoading: componentLoading } = useGetComponentsQuery();


  useEffect(() => {
    if (history) {
    //   console.log("here")
    //  console.log(history);
    }
  }, [history]);

  const handleNextPage = () => {
    if (history?.current_page < history?.total_pages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (history?.current_page > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Container className="mt-4">
      { historyError ? <Container>{historyError.data.msg}</Container> : <>
      <h2>Tan Sessions</h2>
      <ListGroup>
        {history?.users?.map((user) => (
          <ListGroup.Item key={user.id}>
            <Row>
              <Col md={3}>
                <strong>Session ID:</strong> {user.id}
              </Col>
              <Col md={3}>
                <strong>Session Time:</strong> {new Date(user.created_at).toLocaleString()}
              </Col>
              <Col md={3}>
                <strong>Price:</strong> ${user.session_price}
              </Col>
              <Col md={3}>
                <strong>Session Components:</strong>
                {user.session_component?.map((component) => (
                  <Badge key={component.id} bg="info" className="ms-1">
                    {component.component_data.name}: {component.value}
                  </Badge>
                ))}
              </Col>
            </Row>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Pagination controls */}
      <div className="mt-4 d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={handlePreviousPage}
          disabled={history?.current_page <= 1}
        >
          Previous
        </Button>
        <span>
          Page {history?.current_page} of {history?.total_pages}
        </span>
        <Button
          variant="secondary"
          onClick={handleNextPage}
          disabled={history?.current_page >= history?.total_pages}
        >
          Next
        </Button>
      </div></> }
      
    </Container>
  );
};

export default TanProfiles;
