// src/redux/services/tanProfilesApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const baseUrl = process.env.REACT_APP_BASE_API_URL

export const tanProfilesApi = createApi({
  reducerPath: 'tanProfilesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
      // Get the user from the auth slice in the Redux store
      const user = getState().auth?.user;

      // If the user is logged in, add the token to the headers
      if (user?.token) {
        headers.set('authorization', `${user.token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTanProfiles: builder.query({
      query: (page) => ({
        url: `/tan_profiles?page=${page.page}&limit=${page.limit}`,
        method: 'GET',
      }), // replace with your actual endpoint
    }),
    getComponents: builder.query({
      query: () => ({
        url: "/components",
        method: 'GET',
      }), // replace with your actual endpoint
    }),
    saveSessionProfile: builder.mutation({
      query: (body) => ({
        url: '/tan_profiles/tan-profile',
        method: 'POST',
        body: body,
      }),
    }),
    saveExistingSessionProfile: builder.mutation({
      query: (body) => ({
        url: `/tan_profiles/${body.id}`,
        method: 'PUT',
        body: body,
      }),
    }),
  }),
});

export const {
  useGetTanProfilesQuery,
  useLazyGetTanProfilesQuery,
  useGetComponentsQuery,
  useLazyGetComponentsQuery,
  useSaveSessionProfileMutation,
  useSaveExistingSessionProfileMutation
} = tanProfilesApi;
